<template>
  <div class="content">
    <Header />
    <div class="info">
      <div class="plaha">
        <div class="searchForm flex ui fjcS">
          <div class="col90 prev">
            <span>Название категории</span>
            <input type="text" @keyup="searchSection" />
            <ul>
              <li
                v-for="(item, key) in searchList"
                :data-id="item.id"
                :key="key"
                @click="
                  sectionId = item.id;
                  sectionName = item.name;
                  closeUl();
                "
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="col10 forBtn">
            <a href="#!" class="searchBtn">Найти</a>
          </div>
        </div>
      </div>
      <div class="filtersList flex">
        <div class="form">
          <h3>{{ sectionName }}</h3>
          <div class="row ui">
            <span>Название</span>
            <input type="text" v-model="filterValue" />
          </div>
          <div class="row ui">
            <span>Тип </span>
            <DropDown
              :list="typeList"
              :selected="filterType"
              :itemId="0"
              @dropSelect="setType"
            />
          </div>
          <div class="row">
            <a href="#!" class="btn" @click.prevent="addFilter">Сохранить</a>
          </div>
        </div>
        <div class="list flex fWrap fjcS fais">
          <div class="pag" v-for="(item, key) in filtersList" :key="key">
            <div class="filterItem">
              <div class="header flex">
                <span>{{ item.name }}</span>
                <a href="#!" class="edit" @click.prevent="filterValue = item.name; filterId = item.id; filterType = item.type"></a>
                <a
                  href="#!"
                  class="del"
                  :data-id="item.id"
                  @click.prevent="delFilter"
                ></a>
              </div>
              <ul>
                <li v-for="el in item.details" :key="el.id">
                  {{ el.name
                  }}<a
                    href="#!"
                    class="del"
                    :data-id="el.id"
                    @click.prevent="delDetail"
                  ></a>
                </li>
              </ul>
              <div class="footer flex">
                <input type="text" :ref="item.id" />
                <a
                  href="#!"
                  class="save"
                  :data-filter="item.id"
                  @click.prevent="addDetails"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.prev {
  position: relative;
  &.small {
    ul {
      top: 68px;
      left: 15px;
      right: 15px;
    }
  }
  ul {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 52px;
    background: white;
    margin: 0;
    padding: 10px;
    font-size: 13px;
    border-bottom: 1px solid #501a98;
    border-left: 1px solid #501a98;
    border-right: 1px solid #501a98;
    z-index: 9;
    display: none;
    &.active {
      display: block;
    }

    li {
      padding: 3px 0;
      transition: 0.3s ease-out;
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
}
</style>
<script>
import api from "@/components/app/api";
import DropDown from "@/components/app/dropDown";
import Config from "@/components/app/config";
import Helper from "@/components/app/helper";
import Header from "@/components/app/header";
export default {
  name: "Home",
  data() {
    return {
      searchList: [],
      typeList: Config.filterTypeList,
      filterValue: "",
      filterType: 0,
      filterId: 0,
      sectionId: 0,
      sectionName: "Категория",
      filtersList: [],
      filterDetailsFilterId: 0,
      filterDetailsValue: "",
      filterDetailsId: 0,
    };
  },
  watch: {
    sectionId: function () {
      this.list();
    },
  },
  computed: {},
  methods: {
    async delFilter(e) {
      if (confirm("Удаляем фильтр?")) {
        let id = e.target.dataset.id;
        let response = await api.methods.FILTERS_del({
          id,
        });
        this.list();
        Helper.methods.warning(response, "ok");
      }
    },
    async delDetail(e) {
      if (confirm("Удаляем значение?")) {
        let id = e.target.dataset.id;
        let response = await api.methods.FILTERS_delDetails({
          id,
        });
        this.list();
        Helper.methods.warning(response, "ok");
      }
    },
    async addDetails(e) {
      let filter = e.target.dataset.filter;
      let response = await api.methods.FILTERS_addDetails({
        filter: filter,
        value: this.$refs[filter][0].value.trim(),
        id: this.filterDetailsId,
      });
      Helper.methods.warning(response, "ok");
      this.$refs[filter][0].value = "";
      this.filterDetailsId = 0;
      this.list();
    },
    setType(e) {
      this.filterType = e.id;
    },
    async list() {
      //Получаем список фальтров
      this.filtersList = await api.methods.FILTERS_list({
        section: this.sectionId,
      });
    },
    closeUl() {
      console.log("closeUl");
      let arr = document.querySelectorAll(".prev ul");
      arr.forEach((e) => {
        e.classList.remove("active");
        this.searchList = [];
      });
    },
    async searchSection(e) {
      let value = e.target.value;
      if (value.trim().length > 2) {
        this.searchList = await api.methods.SECTIONS_search({
          name: value,
        });
      }
      if (this.searchList.length > 0) {
        e.target.closest("div").querySelector("ul").classList.add("active");
      }
      console.log(this.searchList);
    },
    async addFilter() {
      if (Helper.methods.isEmptyStr(this.filterValue)) {
        Helper.methods.warning("Поле имя не должно быть пустым", "error");
        return;
      }
      if (this.filterType == 0) {
        Helper.methods.warning("Поле тип не должно быть пустым", "error");
        return;
      }
      if (this.sectionId == 0) {
        Helper.methods.warning("Нужно выбрать категорию товаров", "error");
        return;
      }
      let response = await api.methods.FILTERS_add({
        name: this.filterValue.trim(),
        type: this.filterType,
        section: this.sectionId,
        id: this.filterId,
      });
      this.filterValue = "";
      this.filterType = 0;
      this.filterId = 0;

      Helper.methods.warning(response, "ok");
      this.list();
    },
  },
  async mounted() {},
  async created() {},
  components: {
    Header,
    DropDown,
  },
};
</script>